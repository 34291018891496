import { CertemyEnvironment } from './environment.interface';
import pjson from '../../package.json';

export const environment: CertemyEnvironment = {
  name: 'Production',
  release: pjson.version,
  production: true,
  mixpanelToken: '24c86113e68e336fb38f7db5cf1ddf71',
  stripeKey: 'pk_live_osZJosEbLoB6TPe84GnT9SGK',
  sentryKey: 'https://489f4fcdf5b54234aaa6ebbf9ef01a96@sentry-monitoring.certemy.com/9',
  port: '443',
  portAuthz: '443',
  JWT_EXPIRE_TIME: 120,
  CERTEMY_WEBSITE_URL: 'https://certemy.com',
  enableMFA: false,
  GIT_COMMIT: '7e337fb692806efd48079f2abc29c3f97e1f1b78',
  PAYFLOW_URL: 'https://payflowlink.paypal.com',
  SQUARE_URL: 'https://web.squarecdn.com/v1/square.js',
  REFERRAL_URL: 'https://refer.certemyanalytics.com/scripts/tracking/referral.v1.js',
  useReferral: true,
  exactPaymentPath: 'https://checkout.e-xact.com/payment',
  ePayPaymentPath: 'https://epay.wvsto.com/ePayCustomPage/ePay.aspx',
  showExternalLogin: true,
  googleAuthClientId: '14911028123-jc8dr1mot96ofaohd58jjkgmgigbhq6n.apps.googleusercontent.com',
};
